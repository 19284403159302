import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"navbar-personalizado"},[_c(VToolbar,{attrs:{"flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c(VTabs,{staticStyle:{"margin-top":"350px"},attrs:{"align-with-title":"","show-arrows":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider,{attrs:{"color":"primary"}}),_vm._l((_vm.items),function(tab,index){return _c(VTab,{key:index,staticStyle:{"color":"#5c6da7"},attrs:{"disabled":tab.disabled,"href":("#tab-" + index)}},[_vm._v(" "+_vm._s(tab.name)+" ")])})],2)]},proxy:true}])},[[_c(VImg,{staticClass:"align-end cursor-default",attrs:{"id":"img-personalizado","height":"300px","src":_vm.empresaSelecionada.informacoes.banner,"alt":_vm.empresaSelecionada.informacoes.nome}},[_c(VCard,{staticClass:"d-flex align-center justify-space-between",staticStyle:{"background-color":"transparent !important"},attrs:{"width":"100%"}},[_c(VCard,{staticClass:"rounded-tr-xl rounded-0 cursor-default",attrs:{"color":"#FFFFFFE0"}},[_c(VCardTitle,{staticClass:"black--text d-flex align-center justify-center"},[_c(VAvatar,{staticClass:"cursor-default",attrs:{"tile":"","max-width":"none","width":"none"}},[_c('img',{attrs:{"src":_vm.empresaSelecionada.informacoes.logotipo,"alt":_vm.empresaSelecionada.informacoes.nome}})])],1)],1)],1)],1)]],2),_c(VTabsItems,{staticStyle:{"margin-top":"200px"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item,index){return _c(VTabItem,{key:index,attrs:{"value":'tab-' + index}},[_c(item.component,{tag:"component"})],1)}),1),_c(VOverlay,{attrs:{"value":_vm.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"#001325","size":"75"}},[_c('img',{attrs:{"src":_vm.logotipo,"height":"45","alt":""}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }