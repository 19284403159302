<template>
  <v-card class="navbar-personalizado">
    <v-toolbar flat>
      <template slot:img>
        <!-- Banner -->
        <v-img
          id="img-personalizado"
          height="300px"
          :src="empresaSelecionada.informacoes.banner"
          :alt="empresaSelecionada.informacoes.nome"
          class="align-end cursor-default"
        >
          <!-- Informações empresa -->
          <v-card
            width="100%"
            style="background-color: transparent !important"
            class="d-flex align-center justify-space-between"
          >
            <!-- Logo e nome da empresa -->
            <v-card
              class="rounded-tr-xl rounded-0 cursor-default"
              color="#FFFFFFE0"
            >
              <v-card-title
                class="black--text d-flex align-center justify-center"
              >
                <v-avatar
                  class="cursor-default"
                  tile
                  max-width="none"
                  width="none"
                >
                  <img
                    :src="empresaSelecionada.informacoes.logotipo"
                    :alt="empresaSelecionada.informacoes.nome"
                  />
                </v-avatar>
              </v-card-title>
            </v-card>
          </v-card>
        </v-img>
      </template>
      <template v-slot:extension>
        <v-tabs v-model="tab" align-with-title show-arrows style="margin-top: 350px">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-for="(tab, index) in items"
            :key="index"
            :disabled="tab.disabled"
            :href="`#tab-${index}`"
            style="color: #5c6da7"
          >
            {{ tab.name }}
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tab" style="margin-top: 200px">
      <v-tab-item
        v-for="(item, index) in items"
        :key="index"
        :value="'tab-' + index"
      >
        <component :is="item.component"></component>
      </v-tab-item>
    </v-tabs-items>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";

export default {
  data() {
    return {
      logotipo: logo,
      tab: null
    };
  },
  created() {
    this.$store.dispatch(
      "empresas/buscarEmpresaSelecionada",
      this.$route.params.uuid
    );
  },
  computed: {
    empresaSelecionada: {
      get() {
        return this.$store.getters["empresas/getEmpresaSelecionada"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["empresas/getLoading"];
      },
      set(value) {
        return value;
      },
    },
    items: {
      get() {
        return this.$store.getters["empresas/getTabsInformacoesEmpresa"];
      },
    },
  },
};
</script>

<style>
.cursor-default {
  cursor: default;
}
#img-personalizado {
  margin-top: 100px !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.navbar-personalizado {
  margin-top: 80px !important;
}
</style>
